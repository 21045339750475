<template>
  <div>
    <Forget />
  </div>
</template>

<script>
import Forget from '@/components/authentication/Forget.vue'

export default {
  components: {
    Forget,
  },
}
</script>
