<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card
        v-if="status !== 3"
        class="mb-0"
      >
        <h2 class="text-center text-primary">
          タッチファミリーケア
        </h2>
        <div class="text-center text-primary mb-1">
          パスワードの再設定
        </div>
        <b-card-text class="mb-2">
          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body mt-1 mb-2">
              <div class="d-flex">
                <div class="">
                  <b-avatar
                    size="40"
                    src="https://firebasestorage.googleapis.com/v0/b/protex-b9e4c.appspot.com/o/brand%2Fwoman-intro.png?alt=media&token=998c4413-ebc2-46cc-a62d-16c0565dfe32"
                    variant="light-primary"
                  />
                </div>
                <div class="ml-1">
                  パスワードをお忘れの場合は、再設定が必要です。ご登録のメールアドレスに再設定に必要なメールを送信します。
                </div>
              </div>
            </div>
          </b-alert>
        </b-card-text>
        <b-card-text class="mb-2">
          <div class="">
            メールアドレスを入力して｢送信する｣ボタンを押して下さい。
          </div>
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="mailaddress"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              v-if="status === 1"
              variant="primary"
              block
              :disabled="invalid"
              style="margin: 10% 0;"
              @click="send"
            >
              送信する
            </b-button>
            <div
              v-if="status === 2"
              class="text-center mt-2"
            >
              <b-spinner
                style="width: 3rem; height: 3rem;"
                class="spinner_"
                variant="primary"
              />
            </div>
          </b-form>
        </validation-observer>
        <div class="">
          <div class="">
            <b-button
              variant="flat-primary"
              class="btn-wishlist"
              block
              @click="move('login')"
            >
              <feather-icon
                icon="UploadCloudIcon"
                class="mr-50"
              />
              <small>ログインはこちらから</small>
            </b-button>
          </div>
        </div>
        <div class="text-center errorMSG">
          {{ errorMSG }}
        </div>
        <b-alert
          v-if="msg"
          variant="danger"
          show
        >
          <div class="alert-body mt-1 mb-2">
            {{ msg }}
          </div>
        </b-alert>
      </b-card>
      <b-card
        v-if="status === 3"
        class="mb-0"
      >
        <h2 class="text-center text-primary">
          タッチファミリーケア
        </h2>
        <div class="text-center text-primary mb-1">
          パスワードの再設定
        </div>
        <b-card-text class="mb-2">
          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body mt-1 mb-2">
              <div class="d-flex">
                <div class="">
                  <b-avatar
                    size="40"
                    src="https://firebasestorage.googleapis.com/v0/b/protex-b9e4c.appspot.com/o/brand%2Fwoman-intro.png?alt=media&token=998c4413-ebc2-46cc-a62d-16c0565dfe32"
                    variant="light-primary"
                  />
                </div>
                <div class="ml-1">
                  下記のメールアドレスに送信しました。
                </div>
              </div>
            </div>
          </b-alert>
          <h3 class="mt-3 mb-2 text-center text-primary">
            {{ mailaddress }}
          </h3>
        </b-card-text>

        <div class="">
          <div class="">
            <b-button
              variant="flat-primary"
              class="btn-wishlist"
              block
              @click="reset"
            >
              <feather-icon
                icon="UploadCloudIcon"
                class="mr-50"
              />
              <span>やり直す</span>
            </b-button>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormInput, BFormGroup, BCard,
  BCardText, BSpinner, BAlert, BAvatar,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import firebase from 'firebase/app'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardText,
    BSpinner,
    BAlert,
    BAvatar,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      mailaddress: '',
      required,
      email,
      status: 1,
      errorMSG: '',
      msg: null,
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    async send() {
      this.status = 2
      const auth = await firebase.auth()
      await auth.sendPasswordResetEmail(this.mailaddress)

      this.status = 3
    },
    reset() {
      this.status = 1
      this.mailaddress = ''
    },
    move(val) {
      if (this.$route.query) this.$router.push({ name: val, query: this.$route.query })
      else this.$router.push({ name: val })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style scoped>
.spinner_ {
  margin: 3% auto;
}
.errorMSG {
  font-size: 0.8rem;
  color:red
}
.aleart-text {
  font-size: 1rem;
}
</style>
